const themeQuery = (slug: string | string[] | undefined) => {
  return `
  *[_type == "theme" && slug.current == "${slug}"]{
      'slug': slug.current,
      demoSectionDescription,
      demoSectionTitle,
      purchaseUrl,
      excerpt,
      logo,
      title,
      price,
      pageTitle,
      themeDesktop, 
      themeMobile,
      documentationUrl,
      metaTitle,
      metaDescription,
      ogImage,
      ctaSectionTitle,
      ctaSectionDescription,
      ctaGallery,
      featuresSection,
      supportSectionTitle,
      supportSectionDescription,
      supportSectionImage,
      footerCtaSectionTitle,
      footerCtaSectionDescription,
      footerCtaSectionImage,
      _id,
      _type,
      _updatedAt,
      demoList
  }[0]
  `;
};

export default themeQuery;
