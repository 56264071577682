import React, { FC } from 'react';
import { useInView } from 'react-intersection-observer';

import createAnimationArr from '@/src/assets/helpers/createAnimationArr';
import imageHelper from '@/src/assets/helpers/imageHelper';
import { ITheme } from '@/src/assets/interfaces/themeInterface';

import NextImage from '../../UI/nextImage/NextImage';
import Wrapper from '../../UI/wrapper/Wrapper';
import ButtonsGroup from '../../buttonsGroup/ButtonsGroup';

import styles from './MainInfo.module.scss';

type TMain = {
    themeData: Partial<ITheme>;
};

const MainInfo: FC<TMain> = ({ themeData }) => {
    const { price, title, pageTitle, excerpt, logo, themeDesktop, themeMobile, demoList } =
        themeData;

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0,
    });

    return (
        <>
            {themeData && (
                <section className={styles.section__container}>
                    <Wrapper className={styles.section__wrapper}>
                        <div className={styles.section__left}>
                            <div className={styles['logo-wrapper']}>
                                <NextImage
                                    src={imageHelper(logo).url}
                                    alt={imageHelper(logo).alt ?? ''}
                                    className={styles.logo}
                                    fill={true}
                                    sizes="200px"
                                />
                            </div>
                            <div ref={ref} className={styles.section__info}>
                                <h1
                                    className={`heading--1 ${styles.heading} ${
                                        inView && styles.animate__heading
                                    }`}>
                                    {createAnimationArr(pageTitle!, styles.word__wrapper, 0, 0.01)}
                                </h1>
                                <p
                                    className={`excerpt ${styles.excerpt} ${
                                        inView && styles.animate__excerpt
                                    }`}>
                                    {createAnimationArr(excerpt!, styles.word__wrapper, 0.3, 0.01)}
                                </p>
                                <ButtonsGroup
                                    price={price!}
                                    preview={
                                        demoList && demoList[0] ? demoList[0].demoPreview : '/'
                                    }
                                    title={title!}
                                    purchaseUrl={themeData.purchaseUrl!}
                                />
                            </div>
                        </div>
                        <div className={styles.theme__right}>
                            <div className={styles.theme__mobile}>
                                <NextImage
                                    src={imageHelper(themeMobile).url}
                                    alt={imageHelper(themeMobile).alt ?? 'Theme mobile'}
                                    fill
                                    priority
                                    sizes="
                                    (max-width: 376px) 26vw,
                                    (max-width: 480px) 20.73vw,
                                    (max-width: 576px) 23.4vw,
                                    (max-width: 1024px) 22.46vw,
                                    (max-width: 1200px) 12.235vw, 
                                    (max-width: 1700px) 11.63vw,
                                    10.625vw"
                                />
                            </div>
                            <div className={styles.theme__desktop}>
                                <NextImage
                                    src={imageHelper(themeDesktop).url}
                                    alt={imageHelper(themeDesktop).alt ?? 'Theme desktop'}
                                    fill
                                    priority
                                    sizes="
                                              (max-width: 376px) 71.2vw,
                                              (max-width: 480px) 68vw,
                                              (max-width: 576px) 66.75vw,
                                              (max-width: 1024px) 60.84vw, 
                                              (max-width: 1200px) 48vw, 
                                              (max-width: 1700px) 45.55vw, 
                                                                  41.67vw"
                                />
                            </div>
                        </div>
                    </Wrapper>
                </section>
            )}
        </>
    );
};

export default MainInfo;
