import React, { FC } from 'react';

import Button from '../UI/button/Button';
import NextLink from '../UI/nextLink/NextLink';

import styles from './ButtonsGroup.module.scss';

type TBtns = {
    price: number;
    purchaseUrl: string;
    preview: string;
    title: string;
};

const ButtonsGroup: FC<TBtns> = ({ preview, price, title, purchaseUrl }) => {
    return (
        <div className={styles.buttons}>
            <div className={styles.top}>
                <Button
                    target
                    buttonUrl={purchaseUrl}
                    ariaLabelledby="buttons-group-preview"
                    color="blue__divided">
                    <span id="buttons-group-preview">Purchase Now</span> <span>${price}</span>
                </Button>
                <Button
                    ariaLabel={`Preview ${title}`}
                    buttonUrl={preview ?? ''}
                    color="standard"
                    target>
                    <span>Preview {title}</span>
                    <svg
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none">
                        <path
                            d="M6.43674 5.19931L12.7123 5.28769M12.7123 5.28769L12.8007 11.5633M12.7123 5.28769L5.28769 12.7123"
                            stroke="#0A163C"
                            strokeWidth="1.7"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </Button>
            </div>
            <div className={styles.divider}>
                <span className={styles.line}></span>
                <span className={styles.letter}>or</span>
                <span className={styles.line}></span>
            </div>
            <div className={styles.special}>
                <span className={styles.special__text}>⚡ Special Offer</span>
                <NextLink
                    ariaLabel="Purchase All Themes"
                    href="/bundle"
                    className={styles.bundle}
                    ariaLabelledby="buttons-group-purchase">
                    <span id="buttons-group-purchase">Purchase All Themes</span>
                    <div aria-hidden="true" className={styles.colored}>
                        $199
                    </div>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none">
                        <path
                            d="M6.43674 5.69924L12.7123 5.78763M12.7123 5.78763L12.8007 12.0632M12.7123 5.78763L5.28769 13.2123"
                            strokeWidth="1.7"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </NextLink>
            </div>
        </div>
    );
};

export default ButtonsGroup;
