import { StaticImageData } from 'next/image';

import colorSchemesImg from './images/colorSchemes.png';
import fontPairingsImg from './images/fontPairings.png';
import logoPositioningImg from './images/logoPositioning.png';
import multipleDemosImg from './images/multipleDemos.png';

interface FeatureCardsData {
    [key: string]: {
        title: string;
        desc: string;
        image: StaticImageData;
    };
}

export const featureCardsData: FeatureCardsData = {
    multipleDemos: {
        title: 'Multiple Demos in One Theme',
        desc: `Choose from several distinct demos included within one comprehensive theme for a custom fit for your site's style and purpose.`,
        image: multipleDemosImg,
    },
    colorSchemes: {
        title: 'Different Colour Schemes',
        desc: `Customize your site's aesthetic with a variety of color schemes to match your brand or needs .`,
        image: colorSchemesImg,
    },
    fontPairings: {
        title: 'Multiple Font Pairings',
        desc: 'Select from multiple font pairings to ensure readability and character in your typography.',
        image: fontPairingsImg,
    },
    logoPositioning: {
        title: 'Variations of Logo Positioning',
        desc: 'Experiment with different logo placements to give your site a unique and professional appearance',
        image: logoPositioningImg,
    },
};
