import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

import styles from './Breadcrumbs.module.scss';

const Breadcrumbs = () => {
    const router = useRouter();
    const { asPath } = router;
    const pathSegments = asPath
        .replace('tag', '')
        .split('/')
        .filter((segment) => segment !== '' && segment[0] !== '?');
        
    return (
        <nav className={styles.breadcrumbs}>
            <Link href="/" className={styles.link}>
                Home
            </Link>
            {pathSegments.map((segment, index) => {
                const path = `/${pathSegments.slice(0, index + 1).join('/')}`;

                return (
                    <React.Fragment key={index}>
                        <span className={styles.devider}/>
                        <Link href={path} className={styles.link}>
                            {segment.replace(/-/g, ' ')}
                        </Link>
                    </React.Fragment>
                );
            })}
        </nav>
    );
};

export default Breadcrumbs;
