import classNames from 'classnames';
import React, { FC } from 'react';
import { useInView } from 'react-intersection-observer';

import imageHelper from '@/src/assets/helpers/imageHelper';
import IImage from '@/src/assets/interfaces/imageiInterface';

import rocket from '@/public/images/rocket.svg';

import Button from '../../UI/button/Button';
import NextImage from '../../UI/nextImage/NextImage';
import Wrapper from '../../UI/wrapper/Wrapper';

import styles from './CentralStageSection.module.scss';

type TCentral = {
    price: number;
    preview: string;
    themeName: string;
    purchase: string;
    title: string;
    descr: string;
    bgImg: IImage;
};

const CentralStageSection: FC<TCentral> = ({
    preview,
    price,
    themeName,
    title,
    descr,
    bgImg,
    purchase,
}) => {
    const { ref, inView } = useInView({
        initialInView: false,
        rootMargin: `-180px`,
        triggerOnce: true,
    });

    return (
        <section
            className={classNames(styles.cta__section, {
                [styles[`cta__section--animate`]]: inView,
            })}
            ref={ref}>
            <Wrapper>
                <div className={styles.section__container}>
                    <div className={styles.section__left}>
                        <div className={styles.section__info}>
                            {title && <h2 className={`heading--2 ${styles.heading}`}>{title}</h2>}
                            {descr && <p className={`excerpt ${styles.excerpt}`}>{descr}</p>}
                            <div className={styles.buttons}>
                                <Button
                                    target
                                    buttonUrl={purchase}
                                    ariaLabelledby="cta-button"
                                    color="blue__divided">
                                    <span id="cta-button">Purchase Now</span>
                                    <span aria-hidden="true">${price}</span>
                                </Button>
                                <Button
                                    ariaLabel={`Preview ${themeName}`}
                                    buttonUrl={preview}
                                    color="standard"
                                    target>
                                    <span>Preview {themeName}</span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none">
                                        <path
                                            d="M6.43674 5.19931L12.7123 5.28769M12.7123 5.28769L12.8007 11.5633M12.7123 5.28769L5.28769 12.7123"
                                            stroke="#0A163C"
                                            strokeWidth="1.7"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className={styles.background}>
                        {bgImg && (
                            <NextImage
                                src={`${imageHelper(bgImg).url}`}
                                alt="Background"
                                fill
                                loading="lazy"
                                sizes="
                                            (max-width: 414px) 900px,
                                            (max-width: 576px) 1000px,
                                            (max-width: 640px) 150vw,
                                            (max-width: 768px) 140vw,
                                            (max-width: 820px) 130vw,
                                            (max-width: 1200px) 120vw, 
                                            (max-width: 1480px) 95vw, 
                                            104.65vw"
                            />
                        )}
                    </div>
                </div>
            </Wrapper>
        </section>
    );
};

export default CentralStageSection;
