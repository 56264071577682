import { client } from '@/sanity/lib/client';
import { getClient } from '@/sanity/lib/getClient';
import { PreviewThemePage } from '@/src/components/previewPages/previewThemePage/PreviewThemePage';
import PreviewProvider from '@/src/components/providers/previewProvider/PreviewProvider';
import Theme from '@/src/components/screens/Theme/Theme';
import { GetStaticPropsContext } from 'next';
import Link from 'next/link';
import { ParsedUrlQuery } from 'querystring';

import themeQuery from '@/src/assets/queries/themeQuery';

import Custom404 from '../../404';

type TPageProps = {
    data: any;
    slug: string;
    preview: boolean;
    previewToken?: string;
};

interface PathParams extends ParsedUrlQuery {
    slug: string;
}

export default function ThemePage({ data, preview, slug, previewToken }: TPageProps) {
    if (!data) {
        return <Custom404 />;
    }
    if (preview && previewToken) {
        return (
            <PreviewProvider previewToken={previewToken}>
                <PreviewThemePage data={data} slug={slug} />
                <Link className="exit-preview" href={'/api/exit-preview'}>
                    EXIT PREVIEW
                </Link>
            </PreviewProvider>
        );
    }
    return <Theme theme={data} />;
}

export async function getStaticProps(context: GetStaticPropsContext<PathParams>) {
    const preview = context.draftMode || false;
    const previewToken = preview ? process.env.SANITY_API_READ_TOKEN : ``;

    if (preview && !previewToken) {
        throw new Error(
            `Preview mode is active, but SANITY_API_READ_TOKEN is not set in environment variables`
        );
    }

    const _client = getClient(previewToken);
    const slug = context.params?.slug;
    const data = await _client.fetch(themeQuery(slug));

    return {
        props: {
            data: data ?? null,
            slug,
            preview,
            previewToken,
        },
        revalidate: 10,
    };
}

export async function getStaticPaths() {
    const pages = await client.fetch(
        `*[_type in ['theme']] {
        "slug": slug
      }`
    );

    const paths = pages.map((page: any) => ({
        params: {
            slug: page.slug.current,
        },
    }));

    return {
        paths,
        fallback: 'blocking',
    };
}
