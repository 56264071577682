import { client } from '@/sanity/lib/client';
import imageUrlBuilder from '@sanity/image-url';

import { ImageInterface } from '../interfaces/imageInterface';

const builder = imageUrlBuilder(client);

export const getImageUrl = (src: ImageInterface) => {
    const url = builder.image(src);
    return url.url();
};
