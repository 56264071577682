import React, { FC } from 'react';
import { useInView } from 'react-intersection-observer';

import createAnimationArr from '@/src/assets/helpers/createAnimationArr';
import { ITheme } from '@/src/assets/interfaces/themeInterface';
import { advInfoFull } from '@/src/assets/objects/advInfo';
import { getImageUrl } from '@/src/assets/utils/getImageUrl';

import Wrapper from '../../UI/wrapper/Wrapper';
import AdvantageList from '../../advantageList/AdvantageList';
import Breadcrumbs from '../../breadcrumbs/Breadcrumbs';
import DemoCard from '../../demoCard/DemoCard';
import Layout from '../../layout/Layout';
import AdsSection from '../../sections/adsSection/AdsSection';
import BundleSection from '../../sections/bundleSection/BundleSection';
import CentralStageSection from '../../sections/centralStageSection/CentralStageSection';
import DesignSection from '../../sections/designSection/DesignSection';
import MainInfo from '../../sections/mainInfo/MainInfo';
import SupportSection from '../../sections/supportSection/SupportSection';

import styles from './Theme.module.scss';

type TTheme = {
    theme: ITheme;
};

const Theme: FC<TTheme> = ({ theme }) => {
    const {
        title,
        price,
        documentationUrl,
        purchaseUrl,
        metaTitle,
        metaDescription,
        demoSectionTitle,
        demoSectionDescription,
        demoList,
        ogImage,
        slug,
        supportSectionTitle,
        supportSectionDescription,
        supportSectionImage,
        ctaGallery,
        ctaSectionDescription,
        ctaSectionTitle,
        themeDesktop,
        themeMobile,
        footerCtaSectionDescription,
        footerCtaSectionTitle,
        footerCtaSectionImage,
    } = theme;

    const choosePreview = demoList && demoList[0];

    const jsonLd = {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        name: metaTitle || 'Theme',
        image: [`${getImageUrl(themeDesktop)}`, `${getImageUrl(themeMobile)}`],
        url: `${process.env.NEXT_PUBLIC_BASE_URL}themes/${slug}`,
        description:
            metaDescription ||
            'Harness the ethereal power of this platform to captivate your audience and unleash the full potential of your content.',
        sku: `${slug}`,
        brand: {
            '@type': 'Brand',
            name: 'High Five Themes',
        },
        offers: {
            '@type': 'Offer',
            price: `${price}`,
            priceCurrency: 'USD',
            availability: 'https://schema.org/InStock',
            seller: {
                '@type': 'Organization',
                name: 'Ashstones',
            },
        },
    };

    const { ref: inViewRef1, inView: inView1 } = useInView({
        triggerOnce: true,
        rootMargin: `-250px 0px 0px`,
        threshold: 0,
    });

    const { ref: inViewRef2, inView: inView2 } = useInView({
        triggerOnce: true,
        rootMargin: `-250px 0px 0px`,
        threshold: 0,
    });

    const heading = 'Essential Theme Features for Enhanced Online Performance';
    const excerpt =
        'More features, more advantages, and more opportunities to grow your online presence.';

    return (
        <Layout
            meta={{
                metaTitle: metaTitle,
                metaDescription: metaDescription,
                metaImageUrl: !!ogImage && getImageUrl(ogImage),
            }}
            jsonLD={jsonLd}>
            <Wrapper>
                <Breadcrumbs />
            </Wrapper>
            <MainInfo themeData={theme} />
            <section ref={inViewRef1} className={styles.demos}>
                <Wrapper>
                    <h2
                        className={`heading--2 ${styles.heading} ${
                            inView1 && styles.animate__heading
                        }`}>
                        {createAnimationArr(demoSectionTitle, styles.word__wrapper, 0, 0.01)}
                    </h2>
                    <p
                        className={`excerpt ${styles.excerpt} ${
                            inView1 && styles.animate__excerpt
                        }`}>
                        {createAnimationArr(
                            demoSectionDescription,
                            styles.word__wrapper,
                            0.1,
                            0.01
                        )}
                    </p>
                    <ul className={styles.themes__list}>
                        {demoList &&
                            demoList.map((demo, i) => (
                                <li
                                    key={`${demo._key}-${demo.demoName}`}
                                    className={styles.tag__item}>
                                    <DemoCard
                                        demoData={demo}
                                        index={i}
                                        theme={theme}
                                        isThemePage={true}
                                    />
                                </li>
                            ))}
                    </ul>
                </Wrapper>
            </section>
            <AdsSection
                price={price}
                preview={choosePreview.demoPreview ?? '/'}
                themeName={title}
                title={ctaSectionTitle}
                gallery={ctaGallery}
                descr={ctaSectionDescription}
                purchase={purchaseUrl}
            />
            <DesignSection demos={demoList.length} features={theme.featuresSection} />
            <BundleSection className={styles.bundle} showLink={true} />
            <section ref={inViewRef2} className={styles.advantage__section}>
                <Wrapper>
                    <h2
                        className={`heading--2 ${styles.heading} ${
                            inView2 && styles.animate__heading
                        }`}>
                        {createAnimationArr(heading, styles.word__wrapper, 0, 0.01)}
                    </h2>
                    <p
                        className={`excerpt ${styles.excerpt} ${
                            inView2 && styles.animate__excerpt
                        }`}>
                        {createAnimationArr(excerpt, styles.word__wrapper, 0.1, 0.01)}
                    </p>
                    <AdvantageList advList={advInfoFull} />
                </Wrapper>
            </section>
            <SupportSection
                title={supportSectionTitle}
                descr={supportSectionDescription}
                bgImage={supportSectionImage}
                docs={documentationUrl}
            />
            <CentralStageSection
                price={price}
                preview={choosePreview.demoPreview ?? '/'}
                themeName={title}
                title={footerCtaSectionTitle}
                descr={footerCtaSectionDescription}
                bgImg={footerCtaSectionImage}
                purchase={purchaseUrl}
            />
        </Layout>
    );
};

export default Theme;
