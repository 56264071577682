import React, { FC, useEffect, useState } from 'react';
import 'swiper/css';
import 'swiper/css/autoplay';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import imageHelper from '@/src/assets/helpers/imageHelper';
import IImage from '@/src/assets/interfaces/imageiInterface';

import NextImage from '../../UI/nextImage/NextImage';
import Wrapper from '../../UI/wrapper/Wrapper';
import ButtonsGroup from '../../buttonsGroup/ButtonsGroup';

import styles from './AdsSection.module.scss';

type TAds = {
    themeName: string;
    purchase: string;
    price: number;
    preview: string;
    title: string;
    descr: string;
    gallery: IImage[];
};

type TDirection = 'vertical' | 'horizontal';

const AdsSection: FC<TAds> = ({ preview, price, title, gallery, descr, themeName, purchase }) => {
    const [direction, setDirection] = useState<TDirection>('vertical');
    const [gap, setGap] = useState(16);
    useEffect(() => {
        const handleResize = () => {
            setDirection(window.innerWidth > 820 ? 'vertical' : 'horizontal');
            setGap(window.innerWidth > 480 ? 16 : 12);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <section>
            <Wrapper>
                <div className={styles.section__container}>
                    <div className={styles.section__left}>
                        <div className={styles.section__info}>
                            {title && <h2 className={`heading--2 ${styles.heading}`}>{title}</h2>}
                            {descr && <p className={`excerpt ${styles.excerpt}`}>{descr}</p>}
                            <ButtonsGroup
                                purchaseUrl={purchase}
                                price={price}
                                preview={preview}
                                title={themeName}
                            />
                        </div>
                    </div>

                    <Swiper
                        modules={[Autoplay]}
                        direction={direction}
                        className={`${styles.swiper} ${styles.right} gallery-autoplay-swiper`}
                        slidesPerView={'auto'}
                        initialSlide={0}
                        allowTouchMove={false}
                        shortSwipes={false}
                        longSwipes={false}
                        loop={true}
                        autoplay={{
                            disableOnInteraction: false,
                            pauseOnMouseEnter: false,
                            delay: 0,
                        }}
                        spaceBetween={gap}
                        speed={15000}>
                        {[...gallery, ...gallery].map((bg, i) => {
                            return (
                                <SwiperSlide className={styles.background} key={`background-${i}`}>
                                    <NextImage
                                        src={`${imageHelper(bg).url}`}
                                        alt={`Background ${i}`}
                                        fill
                                        sizes="
                                                (max-width: 376px) 280px,
                                                (max-width: 480px) 320px, 
                                                (max-width: 576px) 350px, 
                                                (max-width: 820px) 480px,
                                                (max-width: 840px) 33.67vw, 
                                                (max-width: 1480px) 35.44vw, 
                                                (max-width: 1920px) 42vw, 808px"
                                        loading="lazy"
                                    />
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </div>
            </Wrapper>
        </section>
    );
};

export default AdsSection;
