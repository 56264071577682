import Link from 'next/link';

import Wrapper from '../components/UI/wrapper/Wrapper';

export default function Custom404() {
    return (
        <div className="error-page-container">
            <Wrapper className={`error-page-wrapper`}>
                <div className="error-page">
                    <h1 className="error-page__title">404. Page not found</h1>
                    <p className="error-page__desc">
                        The page you were looking for could not be found. It might have been
                        removed, renamed, or did not exist in the first place.
                    </p>
                    <Link className="error-page__link" href="/">
                        Back To Home Page
                    </Link>
                </div>
            </Wrapper>
        </div>
    );
}
