import { useLiveQuery } from '@sanity/preview-kit';
import { FC } from 'react';

import themeQuery from '@/src/assets/queries/themeQuery';

import { ITheme } from '../../../assets/interfaces/themeInterface/index';
import Theme from '../../screens/Theme/Theme';

interface PreviewThemePageProps {
    data: ITheme;
    slug: string;
}

const PreviewThemePage: FC<PreviewThemePageProps> = ({ data, slug }) => {
    const [themeData] = useLiveQuery(data, themeQuery(slug));

    return <Theme theme={themeData} />;
};

export { PreviewThemePage };
