import classNames from 'classnames';
import React, { FC } from 'react';
import { useInView } from 'react-intersection-observer';

import createAnimationArr from '@/src/assets/helpers/createAnimationArr';

import Wrapper from '../../UI/wrapper/Wrapper';
import DarkMode from '../../darkModeCard/DarkModeCard';
import { FeatureCard } from '../../featureCard/FeatureCard';

import styles from './DesignSection.module.scss';

type TDesign = {
    features: string[];
    demos: number;
};

const DesignSection: FC<TDesign> = ({ features, demos }) => {
    const heading = 'Tailored Features for Peak Performance';
    const excerpt =
        'Discover powerful design features and elements that will help you scale your content.';

    const { ref, inView } = useInView({
        triggerOnce: true,
        rootMargin: `-180px`,
        threshold: 0,
    });

    const renderFeature = (feature: string, index: number, large: boolean = false) => {
        if (feature === 'darkMode') {
            return <DarkMode key={index} large={large} />;
        }
        return <FeatureCard name={feature} key={index} large={large} demosNumber={demos} />;
    };

    const row1Slice = features.length === 3 ? 3 : 2;
    const row2Slice = features.length === 4 ? 5 : 6;

    const renderFeatures = (featuresArr: string[]) => {
        return (
            <div className={styles.features}>
                <div
                    className={classNames(
                        styles.features__row,
                        styles[`features__row--${row1Slice}`]
                    )}>
                    {featuresArr
                        .slice(0, row1Slice)
                        .map((feature, index) => renderFeature(feature, index, row1Slice === 2))}
                </div>
                {featuresArr.length >= 4 && (
                    <div
                        className={classNames(
                            styles.features__row,
                            styles[`features__row--${row2Slice - 3}`]
                        )}>
                        {featuresArr
                            .slice(2, row2Slice)
                            .map((feature, index) => renderFeature(feature, index))}
                    </div>
                )}
            </div>
        );
    };

    return (
        <section className={styles.design__section} ref={ref}>
            <Wrapper>
                <h2 className={`heading--2 ${styles.heading} ${inView && styles.animate__heading}`}>
                    {createAnimationArr(heading, styles.word__wrapper, 0, 0.01)}
                </h2>
                <p className={`excerpt ${styles.excerpt} ${inView && styles.animate__excerpt}`}>
                    {createAnimationArr(excerpt, styles.word__wrapper, 0.1, 0.01)}
                </p>
                {renderFeatures(features)}
            </Wrapper>
        </section>
    );
};

export default DesignSection;
