import { featureCardsData } from './featureCardsData';
import classNames from 'classnames';
import { CSSProperties, FC } from 'react';
import { useInView } from 'react-intersection-observer';

import NextImage from '../UI/nextImage/NextImage';

import styles from './FeatureCard.module.scss';

interface FeatureCard {
    name: string;
    demosNumber: number;
    large?: boolean;
}

const FeatureCard: FC<FeatureCard> = ({ name, demosNumber, large = false }) => {
    const { ref, inView } = useInView({
        initialInView: false,
        rootMargin: `-120px`,
        triggerOnce: true,
    });

    return (
        <div
            className={classNames(styles.feature, {
                [styles['feature--large']]: large,
                [styles['feature--animate']]: inView,
            })}
            ref={ref}>
            <div className={styles.featureMain}>
                <h4 className={classNames(styles.featureMain__title)}>
                    {featureCardsData[name].title}
                </h4>
                <p className={styles.featureMain__desc}>{featureCardsData[name].desc}</p>
            </div>
            {name === 'multipleDemos' ? (
                <div className={styles['demos-wrapper']}>
                    <div className={styles.demos}>
                        <NextImage
                            className={styles.demos__mainImage}
                            src={featureCardsData[name].image.src}
                            alt="Demo"
                            fill
                            loading="lazy"
                            sizes="(max-width: 640px) 32vw, (max-width: 1024px) 30vw, 23.44vw"
                        />
                        {demosNumber &&
                            [...new Array(demosNumber - 1)].map((_, i) => (
                                <div
                                    style={
                                        {
                                            '--i': i + 1,
                                        } as CSSProperties
                                    }
                                    key={`demo-${i}`}
                                    className={styles.demos__block}></div>
                            ))}
                        <div className={styles.demos__count}>
                            <span className={styles.demos__count__number}>{demosNumber}</span>
                            <span className={styles.demos__count__desc}>Demos</span>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={styles.featureImgWrapper}>
                    <NextImage
                        className={styles.featureImg}
                        src={featureCardsData[name].image.src}
                        alt={`${featureCardsData[name].title} image`}
                        fill
                        loading="lazy"
                        sizes="(max-width: 768px) 100vw, 50vw"
                    />
                </div>
            )}
        </div>
    );
};

export { FeatureCard };
